<template>
  <section
    v-if="sidebarComponents.length === 0"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <div class="tw-w-full">
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-center">
        <div class="tw-relative md:tw-w-full">
          <div class="tw-flex tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-gap-y-4">
            <div
              v-html="vars.titleHtml"
              class="tw-mb-12 tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-text-black"
            ></div>
          </div>
          <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleBottom'" :duration="700">
            <Swiper
              effect="fade"
              :clickable="true"
              :modules="[SwiperGrid]"
              :watch-overflow="true"
              @slideChange="onSlideChange"
              :breakpoints="{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 4,
                },
              }"
              :spaceBetween="5"
              @swiper="onSwiper"
              class="tw-max-w-xs md:tw-max-w-none"
            >
              <SwiperSlide
                v-for="(guide, index) in guides"
                :key="`ourguide-guide-${index}`"
                class="!tw-flex !tw-h-auto tw-max-w-none tw-items-center tw-justify-center tw-px-2"
              >
                <guide-card :guide="guide" :index="index" @open-guide-popup="toggleGuidePopup(guide, true)" />
              </SwiperSlide>
            </Swiper>
          </MotionGroup>

          <div>
            <LibrarySliderPagination
              :swiper="swiper"
              variant="v3"
              :can-paginate-back="canPaginateBack"
              :can-paginate-next="canPaginateNext"
            />
          </div>
          <div v-if="vars.buttonLinkUrl" class="tw-mt-10 tw-flex tw-items-center tw-justify-center">
            <nuxt-link :to="vars.buttonLinkUrl" :class="`n-${vars.buttonTypeText}`">{{
              vars.buttonLabelText
            }}</nuxt-link>
          </div>
        </div>
      </div>
    </div>

    <popup v-if="selectedGuide && popupConfig.isActive" :config="popupConfig" custom-width="80%">
      <template #content>
        <div class="tw-w-full tw-text-black">
          <div
            class="tw-mx-auto tw-flex tw-w-full tw-flex-col tw-bg-white md:tw-w-[80%] lg:tw-w-full lg:tw-flex-row"
            style="border-radius: var(--rounded)"
          >
            <div class="tw-w-full lg:tw-max-w-[465px]">
              <LibraryImage
                :w-ratio="1"
                :h-ratio="1.45"
                :src="selectedGuide.popup_cover"
                style="border-radius: var(--rounded)"
              />
            </div>
            <div class="tw-flex tw-w-full tw-flex-col tw-p-6 md:tw-p-10 xl:tw-px-20">
              <div class="tw-text-black" v-html="selectedGuide.popup_title"></div>
              <library-form
                v-if="form"
                :form="form"
                version="v2"
                :component="component"
                :isFormNameVisible="false"
              ></library-form>
            </div>
          </div>
        </div>
      </template>
    </popup>
  </section>

  <template v-else-if="sidebarComponents.length > 0">
    <div
      class="tw-px-4"
      style="border-radius: var(--rounded)"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
    >
      <div class="tw-w-full">
        <div class="tw-flex-col">
          <div class="tw-relative md:tw-w-full">
            <div v-html="vars.titleHtml" class="tw-gap-x-4 tw-pt-6 tw-text-black"></div>
            <Swiper
              :modules="[SwiperPagination]"
              :pagination="false"
              :slides-per-view="1"
              :space-between="20"
              effect="fade"
              :clickable="true"
              :watch-overflow="true"
              @slideChange="onSlideChange"
              @swiper="onSwiper"
              class="tw-w-full tw-max-w-lg lg:tw-max-w-full"
            >
              <SwiperSlide
                v-for="(guide, index) in guides"
                :key="`ourguide-guide-${index}`"
                class="!tw-flex !tw-h-auto tw-max-w-none tw-items-center tw-justify-center tw-px-2"
              >
                <guide-card :guide="guide" :index="index" @open-guide-popup="toggleGuidePopup(guide, true)" />
              </SwiperSlide>
            </Swiper>

            <div>
              <LibrarySliderPagination
                :swiper="swiper"
                variant="v3"
                :can-paginate-back="canPaginateBack"
                :can-paginate-next="canPaginateNext"
              />
            </div>
          </div>
        </div>
      </div>

      <popup v-if="selectedGuide && popupConfig.isActive" :config="popupConfig" custom-width="80%">
        <template #content>
          <div class="tw-w-full tw-text-black">
            <div
              class="tw-mx-auto tw-flex tw-w-full tw-flex-col tw-bg-white md:tw-w-[80%] lg:tw-w-full lg:tw-flex-row"
              style="border-radius: var(--rounded)"
            >
              <div class="tw-w-full lg:tw-max-w-[465px]">
                <LibraryImage
                  :w-ratio="1"
                  :h-ratio="1.45"
                  :src="selectedGuide.popup_cover"
                  style="border-radius: var(--rounded)"
                />
              </div>
              <div class="tw-flex tw-w-full tw-flex-col tw-p-6 md:tw-p-10 xl:tw-px-20">
                <library-form v-if="form" :form="form" version="v2" :component="component"></library-form>
              </div>
            </div>
          </div>
        </template>
      </popup>
    </div>
  </template>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';
import { ComponentMixin } from '~/mixins/component.mixin';
import GuideCard from '~/components/theme4/components/GuideCard.vue';
import Popup from '~/components/common/popup/Popup.vue';

export default defineNuxtComponent({
  name: 'OurGuide',

  mixins: [ComponentMixin],

  components: {
    GuideCard,
    Popup,
  },

  data() {
    return {
      swiper: null,
      form: null,
      canPaginateBack: true,
      canPaginateNext: true,
      selectedGuide: null,
      popupConfig: {
        isActive: false,
      },
    };
  },

  created() {
    /* this.form = this.parseFormV2(this.vars.popup); */
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),

    guides() {
      return this.groupedVariables.guides;
    },
  },

  methods: {
    toggleGuidePopup(guide = null, value = false) {
      if (guide.popup_title) {
        this.selectedGuide = guide;
        this.form = this.parseFormV2(guide.popup);
        this.popupConfig.isActive = value;
      }
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>
