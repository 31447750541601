<template>
  <div
    v-if="variants.v1 === variant"
    class="tw-relative tw-flex tw-w-full tw-flex-col tw-justify-center tw-gap-5 tw-border tw-px-4 tw-py-9 tw-text-start"
    :style="`background-color: var(--c-${vars.backgroundColor}); border-radius: var(--rounded); border-color: var(--c-border)`"
  >
    <h3>{{ vars.titleText }}</h3>
    <p>
      {{ vars.descriptionText }}
    </p>
    <div class="tw-mt-2 tw-flex tw-flex-row tw-gap-3">
      <nuxt-link
        v-for="(button, index) in buttons"
        :key="`card-btn-${index}`"
        :class="`n-${button.type}`"
        :to="button.link"
        :external="true"
      >
        {{ button.label }}
      </nuxt-link>
    </div>
  </div>

  <div
    v-if="variants.v2 === variant"
    class="tw-flex tw-flex-col tw-gap-7 tw-border tw-px-4 tw-py-9"
    :style="`background-color: var(--c-${vars.backgroundColor}); border-radius: var(--rounded)`"
  >
    <h3>{{ vars.titleText }}</h3>
    <library-image
      v-if="vars.photoImage && !vars.videoUrl"
      :src="vars.photoImage"
      :alt="vars.titleText"
      style="border-radius: var(--rounded)"
    />

    <div v-if="vars.videoUrl" class="tw-w-full tw-object-cover xl:tw-col-span-5 xl:tw-row-span-3">
      <library-video-embed
        :video-url="vars.videoUrl"
        :title="vars.titleText"
        :is-popup="true"
        :cover-photo="vars.photoImage"
      />
    </div>
    <div class="tw-flex tw-flex-col tw-gap-2">
      <p
        v-for="(item, index) in descriptions"
        :key="`infobox-descriptions-${index}`"
        class="tw-mt-4 xl:tw-mt-0 xl:tw-whitespace-pre-line"
      >
        {{ item.description }}
      </p>

      <nuxt-link
        :external="true"
        :to="vars.buttonLinkUrl"
        class="tw-mt-2 tw-w-fit xl:tw-mt-5"
        :class="`n-${vars.buttonTypeText}`"
        >{{ vars.buttonLabelText }}</nuxt-link
      >
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'InfoCard',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    variant() {
      return useVariant(this.component);
    },

    buttons() {
      return this.groupedVariables.buttons;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
